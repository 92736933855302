import React from 'react';
import splash2 from "../Images/splash2.png";
import swal from 'sweetalert';

const MyHome = () => {
 
  let outletId = React.createRef();

  const redirectToRetailer = async () => { 
    
    if (outletId.current.value == "") {

      swal("Please Enter Outlet Id", "", "error");

    } else {
      let res = await fetch(`https://api.dailymoo.in/v2/retailerorder/retailer?outletId=${outletId.current.value}`);
      if (res.status == 200) {
    window.location.href = `/Outlet?outletid=${outletId.current.value}`; 
     
    }
      else { 
        swal("Invalid Outlet Id", "", "error");
        outletId.current.value ='';
      }
    }
  }

  return (
    <div  style={{textAlign:"center"}}>
      <div className="col-lg-12">
        <div className="col-lg-4 ">
        </div>
        <div className="col-lg-4 ">
          <img src={splash2} style={{ width: "200px" }} /><br></br>

        </div>
        <div className="col-lg-4 ">
        </div>

      </div>
      <div className="col-lg-12" style={{ marginTop: "50px" }}>
        <div className="col-lg-4 ">
        </div>
        <div className="col-lg-4 ">
          <input type="number"  name="outletid" ref={outletId} id="outletid"  className="form-control" placeholder="Enter Outlet ID"  />
          <button type="button" className="btn" style={{ backgroundColor: "#5C9D04", marginTop: "20px" }} onClick={redirectToRetailer} ><b style={{ color: "white" }}>Submit</b></button>
        </div>
        <div className="col-lg-4 ">
        </div>

      </div>
    </div>
  )
}

export default MyHome;