import React from 'react';
import App from './Components/App';
import { createRoot } from 'react-dom/client';




createRoot(
  document.getElementById('root')
  ).render(
  <App />
  );
