import React from 'react';
import { useEffect, useState } from 'react';
import Select from 'react-select';
import ProductList from './ProductList';
import swal from 'sweetalert';

const Product = () => {
  const [options, setoptions] = useState();
  const [productAllDetails, setProductAllDetails] = useState([]);
  const [click, setClick] = useState(0)
  const [display, setDisplay] = useState("product");
  const [quantity, setQuantity] = useState({
    productId: 0,
    box: 0,
    singlePc: 0
  });

  const [productQantity, setproductQantity] = useState([]);
  const mappProductDetails = async () => {
    const resp = await fetch(`https://api.dailymoo.in/v2/retailerorder/productdetails.php`);
    const productData = await resp.json();

    if (resp.status == 200) {

      const optiondata = productData.response.map(item => {
        return { value: item.id, label: <div><img src={item.image} style={{ width: "40px", height: "40px" }} />{item.sku_name}</div> }
      })
      setoptions(optiondata);

      setProductAllDetails(productData.response);

    }
  }

  const addProduct = () => {

    if (quantity.productId != 0 && ((quantity.box || quantity.singlePc) != 0)) {
      if (productQantity.length > 0) {
        const product = productQantity.find(item => item.productId === quantity.productId);
        if(product){ 
          product.box = +product.box + +quantity.box;
          product.singlePc = +product.singlePc + +quantity.singlePc;
          setproductQantity([...productQantity]);
        }
        else{setproductQantity([...productQantity, quantity])}
        
      } else {
        setproductQantity([quantity])
      }
      setClick(prev => prev + 1);
      setQuantity({

        box: 0,
        singlePc: 0
      })

    } else {
      swal("Please select product and quantity", '', 'error');
    }
  }
  const removeProduct = (productId) => {
    const updatedProduct = productQantity.filter((item) => {
      return item.productId !== productId;
    });
    setClick(prev => prev + 1);
    setproductQantity(updatedProduct);
    console.log(updatedProduct);
  }

  const displayHandler = (e) => { 
    setDisplay(e);
  }

 // console.log(display)
  useEffect(() => {
    mappProductDetails();
  }, [])
  return (
    <>

    {display === "product" && <div>
      <div style={{ marginTop: "20px" }}>

        <Select options={options} onChange={opt => setQuantity({ ...quantity, productId: opt.value })} />



      </div>
      <div className="card">
        <p style={{ color: "#5C9D04" }}><b>Add Quantity :</b></p>

        <div className="box">
          <label>Box:</label>
          <input type="number" placeholder="Box" value={quantity.box} onChange={(e) => setQuantity({ ...quantity, box: e.target.value })} ></input>

        </div>
        <div className="box" style={{ marginTop: "10px" }}>

          <label>Pc:</label>
          <input type="number" placeholder='Pc' value={quantity.singlePc} onChange={(e) => setQuantity({ ...quantity, singlePc: e.target.value })}></input>
        </div>

      </div>
      <div style={{ marginTop: "10px" }}>
        <button type="button" className='btn btn-info' style={{ float: "right" }} onClick={addProduct}>ADD</button>

      </div>
      </div>}
      {productQantity && productQantity.length > 0 && <ProductList productQantity={productQantity} clickcount={click} product={productAllDetails} handler={removeProduct} displayhandler={displayHandler} display={display}/>}

     
    </>

  )

}
export default Product;