import './App.css';
import MyHome from './Home';
import MyOutlet from './Outlet';
import { Routes, Route, BrowserRouter } from "react-router-dom";
import Product from './Product';

function App() {
  return (
    <div className="App">
      <BrowserRouter>
      <Routes>
        <Route path="/" element={<MyHome />} />
        <Route path="/Outlet" element={<MyOutlet />} />
        <Route path="/Product" element={<Product />} />
      </Routes>
      </BrowserRouter>

{/* <Product /> */}
    </div>
  );
}

export default App;
