import React from 'react';
import { useEffect, useState } from 'react';
import swal from 'sweetalert';


const ProductList = (props) => {

  const [mergeDetails, setMergeDetails] = useState([]);
  const [otpdom, setotpdom] = useState(false);
  const [submitdom, setsubmitdom] = useState(false);
  var retailerProductData = [];
  var outletId = '';
  var retailerPhoneNo = '';
  var totalPrice = 0;
  var url = new URL(document.URL);
  useEffect(() => {
    merge()


  }, [props.clickcount]);

  const merge = () => {

    if (mergeDetails.length > 0) {

      setMergeDetails([...props.productQantity.map(v => ({ ...v, ...props.product.find(sp => sp.id === v.productId) }))])
    } else {
      setMergeDetails(props.productQantity.map(v => ({ ...v, ...props.product.find(sp => sp.id === v.productId) })))
    }
  }


  const sendOtp = async () => {
    retailerPhoneNo = url.searchParams.get("phoneNo");
    swal({
      title: "Please wait.....",
      buttons: false,
    });
    let otpres = await fetch(`https://api.dailymoo.in/v2/retailerorder/otp.php?phoneNumber=${retailerPhoneNo}`);
    swal.close();
    if (otpres.status == 200) {
      setsubmitdom(true);
    }
    // console.log('hi');
    // setsubmitdom(true)
  }

  const clickhandle = () => {
    props.displayhandler("product");
    //c//onsole.log('hi')

    setotpdom(false)
  }
  //console.log(mergeDetails);
  const summaryClick = () => {
    props.displayhandler("summary");
    setotpdom(true)
  }

  const placeOrder = async () => {
    swal({
      title: "Please wait.....",
      buttons: false,
    });
    outletId = url.searchParams.get("outletId");
    retailerPhoneNo = url.searchParams.get("phoneNo");
    var otp = document.getElementById("otp").value;
    if (otp != '') {
      var orderData = {
        "outletId": outletId,
        "total_amount": totalPrice.toFixed(2),
        "retailer_phone_no": retailerPhoneNo,
        "products": retailerProductData,
        "otp": otp
      }
      var orderResponse = await fetch('https://api.dailymoo.in/v2/retailerorder/retailer', {
        method: 'POST',
        body: JSON.stringify(orderData)
      });
      swal.close();
      let textResp = await orderResponse.json();
      if (orderResponse.status == 200) {
        swal("Order Placed Successfully", textResp.response, 'success').then(function () {
          window.location.href = `/`;
        });
        //  props.displayhandler("product");


      } else {
        swal("Order Placed Failed", textResp.response, 'error');
      }
    }
    else {
      swal("Please enter otp", '', 'error');
    }
    // console.log(orderData)


    // console.log(retailerProductData)
    // console.log(totalPrice.toFixed(2))
  }

  return (
    <>
      {props.display === "product" && <div style={{ marginTop: "80px" }}>

        {mergeDetails.map((item, index) => {

          return (

            <div key={index} style={{ marginTop: "10px" }}>



              <div >
                <img src={item.image} style={{ width: "50px", height: "50px" }} /> <span style={{ float: "right", marginRight: "100px", marginTop: "5px" }}>{item.sku_name}<br />Box:{item.box},  Pieces:{item.singlePc} <i className="fa fa-trash" aria-hidden="true" onClick={() => props.handler(item.id)} style={{ marginLeft: "20px", color: "red", fontSize: "25px" }}></i> </span> </div>


            </div>

          )
        })}

        <div>
          <button type="button" className='btn btn-success' style={{ float: "right" }} onClick={summaryClick} >Summary</button>
        </div>
      </div>}
      {props.display === "summary" && <div>
        <button type="button" className='btn btn-info' style={{ float: "right" }} onClick={clickhandle} >Edit</button>
      </div>}

      {props.display === "summary" && <div style={{ marginTop: "30px" }}>
        <table className='table table-stripped'>
          <thead>
            <tr>
              <th>Product Name</th>
              <th>Box</th>
              <th>Pieces</th>
              <th>Total Quantity</th>
              <th>Price</th>
            </tr>
          </thead>
          <tbody>

            {mergeDetails.map((item, index) => {
              var eachProductPrice = ((item.box * item.pc) + +item.singlePc) * item.retailer_order_rate;
              var productQantity = ((item.box * item.pc) + +item.singlePc);
              totalPrice += eachProductPrice;
              retailerProductData.push({
                "product_id": item.productId,
                "box": item.box,
                "pieces": item.singlePc,
                "productQantity": productQantity,
                "product_price": eachProductPrice.toFixed(2)
              })
              return (

                <tr key={index}>
                  <td>{item.sku_name}</td>
                  <td>{item.box}</td>
                  <td>{item.singlePc}</td>
                  <td> {(item.box * item.pc) + +item.singlePc}</td>
                  <td>{(((item.box * item.pc) + +item.singlePc) * item.retailer_order_rate).toFixed(2)}</td>
                </tr>
              )


            })}
            <tr>
              <td></td><td></td><td></td><td>Total :</td><td >{totalPrice.toFixed(2)}</td>
            </tr>

          </tbody>
        </table>


      </div>}
      {otpdom &&

        <div style={{ textAlign: "center" }}>
          <div className="col-lg-12">
            <div className="col-lg-4" >
              <button type="button" className={!submitdom ? 'btn btn-success show' : 'btn btn-success hide'} onClick={sendOtp}  style={{ marginLeft: "45%" }}  >Send OTP</button>

            </div>
            <div className="col-lg-4">


              <input type="number" className={submitdom ? 'form-control show' : 'form-control hide'} id="otp" placeholder='Enter Otp'   ></input><br>
              </br>
              <button type="button" className={submitdom ? 'btn btn-success show' : 'btn btn-success hide'} onClick={placeOrder}  style={{ marginLeft: "45%" }}>Submit</button>

            </div>
            <div className="col-lg-4"></div>

          </div>
        </div>

      }
    </>
  )
}

export default ProductList;