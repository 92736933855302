import React from 'react';
import { useEffect, useState } from 'react';
import swal from 'sweetalert';
const OutletDetails = () => {

  const [outletDetails, setOutletDetails] = useState();
  var outletId = "";
  useEffect(() => {
    document.title = "Outlet Details";
    var url = new URL(document.URL);
    outletId = url.searchParams.get("outletid");
    mappOutletDetails(outletId);
//console.log(outletId)

  }, [])
  const mappOutletDetails = async (outletId) => {

    let outletRes = await fetch(`https://api.dailymoo.in/v2/retailerorder/retailer?outletId=${outletId}`);

    let outletData = await outletRes.json();

    setOutletDetails(outletData);

  }


  const customerOffer = () => {
    swal("Offer Not Active", "", 'warning');

  }
  const orderPlacement = () => { 

    window.location.href = `/Product?outletId=${outletDetails.outlet_id}&&phoneNo=${outletDetails.phoneno}`; 

  }
  
  return (
    <div  style={{textAlign:"center"}}>


      <div className="col-lg-12">
        <div className='col-lg-4'></div>
        <div className='col-lg-4'><button className="button button4">Outlet</button>
          <button className="button button4">Product</button>
          <button className="button button4">Summary</button></div>
        <div className='col-lg-4'></div>
      </div>

      {outletDetails && <> <div className="row" style={{ marginTop: "10px", border: "1px solid #5C9D04", borderRadius: "15px", marginLeft: "5px", marginRight: "5px" }}>

        <div className="col-lg-12">
          <table className="table table-striped" >
            <thead>

            </thead>
            <tbody>
              <tr>
                <td>Outlet Id <br />(ଆଉଟଲେଟ୍ ଆଇଡି)</td>
                <td>{outletDetails.outlet_id}</td>
              </tr>
              <tr>
                <td>Shop Name <br />(ଦୋକାନ ନାମ)</td>
                <td>{outletDetails.outlet_name}</td>
              </tr>
              <tr>
                <td>Owner Name <br />(ମାଲିକ ନାମ)</td>
                <td>{outletDetails.ownername}</td>
              </tr>
              <tr>
                <td>Phone Number <br />(ଫୋନ୍ ନମ୍ବର)</td>
                <td>{outletDetails.phoneno}</td>
              </tr>
              <tr>
                <td>Address <br />(ଠିକଣା)</td>
                <td>{outletDetails.address}</td>
              </tr>
            </tbody>
          </table>
        </div>

      </div>
        <div className="row " style={{ marginTop: "18px", marginBottom: "10px" }}>
          <div className="col-lg-12">
            <button type="button" style={{ backgroundColor: "#5C9D04", color: "white" }} className="btn" onClick={orderPlacement}  >Order Placement</button>
            <button type="button" style={{ backgroundColor: "#26d106", color: "white", marginLeft: "10px" }} className="btn" onClick={customerOffer} >Customer Offer</button>
          </div>
        </div>
      </>}
    </div>
  )

}

export default OutletDetails;